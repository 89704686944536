import { useState, useEffect } from "react";
import keccak256 from "keccak256";
import { ethers } from "ethers";
import { get, map, isEmpty, findIndex } from "lodash";
import { MerkleTree } from "merkletreejs";
const importPromise = import("../contracts/token_ranks.json");

export const loadTokenRanks = async () => {
  const res = (await importPromise).default ?? {};
  return res;
};

export const useTokenRanksJson = () => {
  const [tokenRanks, setTokenRanks] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadTokenRanks();
      // load hashes
      // result.hashes = map(result.hashes, (h) => {
      //   return Buffer.from(h);
      // });
      // console.log("result", result);
      setTokenRanks(result);
    };
    void loadFunc();
  }, []);
  return tokenRanks;
};

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { ethers } from "ethers";
import { get, has, map } from "lodash";
import { useContractConfig } from "./hooks/useContractConfig";
import { useNetwork } from "./hooks/useNetwork";
import { useSigner } from "./hooks/useSigner";
import {
  useProvider,
  useProviders,
  useSpecificProviders,
  useIsMetaMask,
  useAddAccountsChangedListener,
} from "./hooks/useProvider";
import { useAddress } from "./hooks/useAddress";
import { useContractLoaderReload } from "./hooks/useContractLoaderReload";
// import { useContractLoader } from "eth-hooks";
import * as styles from "./App.module.scss";
import HomepageHero from "./components/HomepageHero";
import Tokens from "./components/Tokens";

import { contractAddressByNetwork } from "./helpers/contractAddresses";
import MintSection from "./components/MintSection";
import MintPass from "./components/MintPass";
import Survivors from "./components/Survivors";
import Players from "./components/Players";
import MobileDetect from "mobile-detect";
import { useBalanceOf } from "./hooks/useBalanceOfContract";
import { useTokensOfOwner } from "./hooks/useTokensOfOwner";

const ua = navigator.userAgent;

const md = new MobileDetect(ua);

const isIos = md.os() === "iOS";
const isMobile = md.mobile() !== null;

export function AppProvider(props) {
  const [provider, setProvider] = useProvider(window.ethereum);
  return (
    <App
      provider={provider}
      setProvider={setProvider}
      wndEthereum={window.ethereum}
    ></App>
  );
}

export function App(props) {
  const { provider, setProvider, wndEthereum } = props;

  const providers = useProviders(wndEthereum);
  const selectedProvider = get(provider, "provider.selectedProvider", null);
  const [metaMaskProvider, coinbbaseWalletProvider] = useSpecificProviders(
    wndEthereum,
    provider,
    providers
  );
  const isMetaMask = useIsMetaMask(
    provider,
    selectedProvider,
    metaMaskProvider
  );
  const [balanceOf, setBalanceOf] = useState(null);
  const [mintPassTokens, setMintPassTokens] = useState(null);

  const [network, updateNetwork] = useNetwork(provider);

  const [signer, updateSigner] = useSigner(provider);
  // const isMetaMask = true;
  useAddAccountsChangedListener(provider, updateSigner);

  const [EthNetworkName, setEthNetworkName] = useState("mainnet");
  // const [EthNetworkName, setEthNetworkName] = useState("ropsten");

  let networkToShow = network !== undefined ? network.name : EthNetworkName;

  const contractConfig = useContractConfig(provider, networkToShow);

  const [chainId, setChainId] = useState();
  useEffect(() => {
    if (network !== undefined) {
      setChainId(network.chainId);
    }
  }, [network]);

  const readContracts = useContractLoaderReload(
    provider,
    contractConfig,
    chainId
  );
  const writeContracts = useContractLoaderReload(
    signer,
    contractConfig,
    chainId
  );

  const signerAddress = useAddress(signer);

  const updateBalanceOf = () => {
    // balanceOf
    if (
      readContracts &&
      readContracts.ETHWalkersMiami &&
      !!provider &&
      !!signerAddress
    ) {
      readContracts.ETHWalkersMiami.balanceOf(signerAddress).then((p) => {
        setBalanceOf(p.toNumber());
      });
    }
  };

  const updateMintPassTokens = () => {
    // balanceOf
    if (
      readContracts &&
      readContracts.ETHWalkersMiami &&
      !!provider &&
      !!signerAddress
    ) {
      readContracts.ETHWalkersMintPass.tokensOfOwner(signerAddress).then(
        (tokens) => {
          console.log("tokens", tokens);
          const numTokens = map(tokens, (t) => t.toNumber());
          setMintPassTokens(numTokens);
        }
      );
    }
  };

  useEffect(() => {
    updateBalanceOf();
    updateMintPassTokens();
  }, [provider, signerAddress, readContracts]);

  // get MintPass tokens

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <HomepageHero
              signer={signer}
              network={network}
              updateNetwork={updateNetwork}
              isMobile={isMobile}
              isMetaMask={isMetaMask}
              isIos={isIos}
              provider={provider}
              updateSigner={updateSigner}
              heroInfoSection={
                <>
                  <MintSection
                    signer={signer}
                    provider={provider}
                    metaMaskProvider={metaMaskProvider}
                    coinbbaseWalletProvider={coinbbaseWalletProvider}
                    setProvider={setProvider}
                    updateSigner={updateSigner}
                    network={network}
                    updateNetwork={updateNetwork}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    isMobile={isMobile}
                    balanceOf={balanceOf}
                    updateBalanceOf={updateBalanceOf}
                  ></MintSection>
                </>
              }
            ></HomepageHero>
          }
        ></Route>
        <Route
          path="/mintpass"
          element={
            <HomepageHero
              signer={signer}
              network={network}
              updateNetwork={updateNetwork}
              isMobile={isMobile}
              isMetaMask={isMetaMask}
              isIos={isIos}
              provider={provider}
              updateSigner={updateSigner}
              heroInfoSection={
                <>
                  <MintPass
                    signer={signer}
                    provider={provider}
                    metaMaskProvider={metaMaskProvider}
                    coinbbaseWalletProvider={coinbbaseWalletProvider}
                    setProvider={setProvider}
                    updateSigner={updateSigner}
                    network={network}
                    updateNetwork={updateNetwork}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    isMobile={isMobile}
                    balanceOf={balanceOf}
                    updateBalanceOf={updateBalanceOf}
                    mintPassTokens={mintPassTokens}
                  ></MintPass>
                </>
              }
            ></HomepageHero>
          }
        ></Route>
        <Route
          path="/survivors"
          element={
            <HomepageHero
              signer={signer}
              network={network}
              updateNetwork={updateNetwork}
              isMobile={isMobile}
              isMetaMask={isMetaMask}
              isIos={isIos}
              provider={provider}
              updateSigner={updateSigner}
              heroInfoSection={
                <>
                  <Survivors
                    signer={signer}
                    provider={provider}
                    metaMaskProvider={metaMaskProvider}
                    coinbbaseWalletProvider={coinbbaseWalletProvider}
                    setProvider={setProvider}
                    updateSigner={updateSigner}
                    network={network}
                    updateNetwork={updateNetwork}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    isMobile={isMobile}
                    balanceOf={balanceOf}
                    updateBalanceOf={updateBalanceOf}
                  ></Survivors>
                </>
              }
            ></HomepageHero>
          }
        ></Route>
        <Route
          path="/players"
          element={
            <HomepageHero
              signer={signer}
              network={network}
              updateNetwork={updateNetwork}
              isMobile={isMobile}
              isMetaMask={isMetaMask}
              isIos={isIos}
              provider={provider}
              updateSigner={updateSigner}
              heroInfoSection={
                <>
                  <Players
                    signer={signer}
                    provider={provider}
                    metaMaskProvider={metaMaskProvider}
                    coinbbaseWalletProvider={coinbbaseWalletProvider}
                    setProvider={setProvider}
                    updateSigner={updateSigner}
                    network={network}
                    updateNetwork={updateNetwork}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    isMobile={isMobile}
                    balanceOf={balanceOf}
                    updateBalanceOf={updateBalanceOf}
                  ></Players>
                </>
              }
            ></HomepageHero>
          }
        ></Route>
      </Routes>
    </Router>
  );
}
//
export default App;
/*
<Tokens
signer={signer}
readContracts={readContracts}
balanceOf={balanceOf}
></Tokens>
*/
// gas tracker api link:
// https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=YourApiKeyToken

import { useState, useEffect } from "react";
import keccak256 from "keccak256";
import { ethers } from "ethers";
import { get, map, isEmpty, findIndex } from "lodash";
const winnersImport = import("../contracts/winners_list.json");
const playersImport = import("../contracts/s1players.json");
const survivorsImport = import("../contracts/s1survivors.json");

export const loadWinners = async () => {
  const res = (await winnersImport).default ?? {};
  return res;
};

export const loadPlayers = async () => {
  const res = (await playersImport).default ?? {};
  return res;
};

export const loadSurvivors = async () => {
  const res = (await survivorsImport).default ?? {};
  return res;
};

export const useWinnersJson = (provider) => {
  const [whitelist, setWhitelist] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadWinners();
      // load hashes
      result.hashes = map(result.hashes, (h) => {
        return Buffer.from(h);
      });
      // console.log("result", result);
      setWhitelist(result);
    };
    void loadFunc();
  }, [provider]);
  return whitelist;
};

export const useSurvivorsJson = (provider) => {
  const [whitelist, setWhitelist] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadSurvivors();
      // load hashes
      result.hashes = map(result.hashes, (h) => {
        return Buffer.from(h);
      });
      // console.log("result", result);
      setWhitelist(result);
    };
    void loadFunc();
  }, [provider]);
  return whitelist;
};

export const usePlayersJson = (provider) => {
  const [whitelist, setWhitelist] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadPlayers();
      // load hashes
      result.hashes = map(result.hashes, (h) => {
        return Buffer.from(h);
      });
      // console.log("result", result);
      setWhitelist(result);
    };
    void loadFunc();
  }, [provider]);
  return whitelist;
};

export const useWhitelistIndex = (whitelist, address) => {
  const [whitelistIndex, setWhitelistIndex] = useState(-1);

  useEffect(() => {
    const loadFunc = async () => {
      if (address === null || address === undefined || isEmpty(whitelist)) {
        return false;
      }
      const addrHash = keccak256(
        ethers.utils.defaultAbiCoder.encode(["address"], [address])
      );

      // find index
      const i = findIndex(whitelist.hashes, (h) => {
        const c = addrHash.compare(h);
        return c === 0;
      });

      setWhitelistIndex(i);
    };
    void loadFunc();
  }, [whitelist, address]);

  return whitelistIndex;
};

export const useWhitelistEntry = (whitelist, signerAddress) => {
  const [whitelistEntry, setwhitelistEntry] = useState(-1);

  useEffect(() => {
    const loadFunc = async () => {
      if (
        signerAddress === null ||
        signerAddress === undefined ||
        isEmpty(whitelist)
      ) {
        return null;
      }
      const entry = get(whitelist, signerAddress.toLowerCase(), null);
      setwhitelistEntry(entry);
    };
    void loadFunc();
  }, [whitelist, signerAddress]);

  return whitelistEntry;
};

import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { get } from "lodash";

export const useAddAccountsChangedListener = (provider, updateSigner) => {
  useEffect(() => {
    if (!!provider) {
      provider.provider.on("accountsChanged", (accounts) => {
        console.log("accountsChanged", accounts);
        updateSigner();
      });
    }
  }, [provider]);
};

export const useIsMetaMask = (provider, selectedProvider, metaMaskProvider) => {
  // const selectedProvider = useSelectedProvider(provider)
  const [isMetaMask, setIsMetaMask] = useState(false);

  const update = () => {
    if (!!metaMaskProvider) {
      if (!!selectedProvider) {
        setIsMetaMask(selectedProvider === metaMaskProvider);
      } else {
        setIsMetaMask(get(provider, "provider.isMetaMask", false));
      }
    }
  };

  useEffect(() => {
    update();
  }, [provider, selectedProvider, metaMaskProvider]);

  return isMetaMask;
};

export const useProviders = (wndEthereum) => {
  const [providers, setProviders] = useState(null);

  const updateProviders = () => {
    if (!!wndEthereum) {
      setProviders(wndEthereum.providers);
    }
  };

  useEffect(() => {
    updateProviders();
  }, [wndEthereum]);

  return providers;
};

export const useSpecificProviders = (wndEthereum, provider, providers) => {
  const [metaMaskProvider, setMetaMaskProvider] = useState(null);
  const [coinbaseWalletProvider, setCoinbaseWalletProvider] = useState(null);

  const updateProviders = () => {
    let mmp = null;
    let cbp = null;
    if (!!wndEthereum && !!provider) {
      if (!!providers) {
        mmp = providers.find((provider) => provider.isMetaMask);
        cbp = providers.find((provider) => provider.isCoinbaseWallet);
        setMetaMaskProvider(mmp);
        setCoinbaseWalletProvider(cbp);
      } else {
        // !providers
        if (get(provider, "provider.isMetaMask", false)) {
          setMetaMaskProvider(provider.provider);
        } else {
          setCoinbaseWalletProvider(provider.provider);
        }
      }
    }
    // return p;
  };

  useEffect(() => {
    updateProviders();
  }, [wndEthereum, provider, providers]);

  return [metaMaskProvider, coinbaseWalletProvider];
};

export const useProvider = (wndEthereum) => {
  const [provider, setProvider] = useState(null);

  // maygbe select provider?
  // const metamaskProvider = window.ethereum.providers.find((provider) => provider.isMetaMask);

  // const onAccountsChanged = (accounts) => {
  //   console.log('P accounts changed', accounts)
  // }

  const updateProvider = () => {
    let p = null;
    if (wndEthereum !== undefined) {
      // console.log("wndethereum !== undefined");
      p = new ethers.providers.Web3Provider(wndEthereum, "any");
      // if (provider !== null) {
      //   provider.provider.off('accountsChanged', onAccountsChanged)
      // }
      // p.provider.on('accountsChanged', onAccountsChanged)

      setProvider(p);
    }
    return p;
  };

  useEffect(() => {
    const p = updateProvider();
    const onNetworkHandler = () => {
      // console.log("got network change");
      p.off("network", onNetworkHandler);
      updateProvider();
    };
    if (p !== null) {
      p.on("network", onNetworkHandler);
    }
  }, [wndEthereum]);

  return [provider, setProvider];
};

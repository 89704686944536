import * as cn from "classnames";
import { Link } from "react-router-dom";
import * as styles from "../App.module.scss";
import { useAddress } from "../hooks/useAddress";

export default function HomepageHero(props) {
  const { children, heroInfoSection } = props;

  return (
    <div className={cn(styles.fullwidthbustout, "relative ")}>
      <main className={styles.main_mintsection}>
        <header
          className={cn(
            "relative flex items-center justify-center ",
            styles.homepageheaderwrapper
          )}
        >
          <div className="">
            <div className="flex items-center justify-center  flex-col">
              <div className={cn(" z-30 p-3 mt-8", styles.homeheader)}>
                <h1
                  className={cn(
                    styles.homeheadertext,
                    styles.maxwidth93,
                    "text-5xl",
                    "text-white",
                    "text-center"
                  )}
                >
                  Season&nbsp;2: Miami&nbsp;NFT
                </h1>
                <div
                  className={cn(
                    "font-semibold text-xl text-center text-white",
                    styles.home_subtitle
                  )}
                >
                  <p>
                    The zombie virus that devastated and ultimately destroyed
                    <br />
                    the small community of Portstown, Louisiana is spreading,
                    <br />
                    and there seems to be very little hope of stopping it.
                  </p>
                </div>
              </div>
              {heroInfoSection}
            </div>
          </div>
        </header>

        {/* More main page content here... */}
        {children}
      </main>
    </div>
  );
}

import { useEffect, useState } from "react";

export const useMintCount = (initial, new_max) => {
  const [mintCount, setMintCount] = useState(initial);
  const update = () => {
    setMintCount(Math.min(initial, new_max));
  };
  useEffect(update, [initial, new_max]);

  return [mintCount, setMintCount];
};

export const useMintCountMax = (initial, new_max) => {
  const [mintCount, setMintCount] = useState(initial);
  const update = () => {
    setMintCount(Math.max(initial, new_max));
  };
  useEffect(update, [initial, new_max]);

  return [mintCount, setMintCount];
};

export const useMintCountReset = (initial_reset) => {
  const [mintCount, setMintCount] = useState(initial_reset);
  const update = () => {
    setMintCount(initial_reset);
  };
  useEffect(update, [initial_reset]);

  return [mintCount, setMintCount];
};

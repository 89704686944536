import { get } from "lodash";

export const contractAddressByNetwork = {
  localhost: {},
  ropsten: {
    baseUrl: "https://ropsten.etherscan.io/address/",
    Diamond: "0x5d4102eac83a3bbab5327921b31a381526159b95",
    DiamondInit: "0x0a19cd4e9a1c326536a2633b3f21362fe594ed37",
    DiamondCutFacet: "0xdf1443972a1291b7ece97497bba7c3c40a2a0b33",
    OwnershipFacet: "0xc1a6a510b04de9090ec380c77799f3c4ddb5ca4f",
    DiamondLoupeFacet: "0x965d0952ad0daa235eb0dba933a061d288edae9a",
  },
  homestead: {
    baseUrl: "https://etherscan.io/address/",
    Diamond: "0x600d9b873061f39e172a93422725539164e5fb1e",
    DiamondInit: "0x31aaddd5df920970e9ec6bd5ac91bff9cd1c0479",
    DiamondCutFacet: "0x0d0d3abf3b2605a03fecf813f072ea7f4254b2ab",
    OwnershipFacet: "0x5d4102eac83a3bbab5327921b31a381526159b95",
    DiamondLoupeFacet: "0xdf1443972a1291b7ece97497bba7c3c40a2a0b33",
  },
  matic: {
    baseUrl: "https://polygonscan.com/address/",
    Diamond: "0x9161a8a31998c7b7204fe54ed1c5f5261c3dfd1f",
    DiamondInit: "0x6ca5a94d4cb7736f36b1b2074a5dc68269d21699",
    DiamondCutFacet: "0x3bdba941b6382fb7764ccf0f1242510e59eb2404",
    OwnershipFacet: "0x80321cacc5fc40cc6af8b9742c8123805cea09da",
    DiamondLoupeFacet: "0x006622c2716e26a2200aee9d3225e8d659a4c9f7",
  },
};

export const getAddressByNetwork = (network, contractName) => {
  const p = `${network}.${contractName}`;
  return get(contractAddressByNetwork, `${network}.${contractName}`, null);
};

const loadTime = new Date().getTime() / 1000;
// const presaleLaunch = 1655316000;

const allowListSale = 1657569600; // 7/11 at 1pm PDT
const publicSale = 1657656000; // 7/12 at 1pm PDT
// const publicSale = 0;
// const endSale = 1658779200; // 7/25 at 1pm PDT
const endSale = 1693526400; // longer
// console.log('loadTime', loadTime)
// console.log('         1648677600')
// console.log(loadTime > 1648677600)
export const kPrice = 0.042;

export const kWhitelistLive = loadTime > allowListSale;

export const kPublicSaleLive = loadTime > publicSale;
export const kSaleEnded = loadTime > endSale;

export const kOpenSeaLink = "https://opensea.io";
// TODO: update with link like https://opensea.io/collection/eth-walkers-portstown

import React, { useCallback, useState, useEffect } from "react";
import { isEmpty, isNil } from "lodash";
import { ethers } from "ethers";

import * as cn from "classnames";
import * as styles from "../App.module.scss";

import { Link } from "react-router-dom";
import { useAddress } from "../hooks/useAddress";
import { useWinnersJson, useWhitelistEntry } from "../hooks/useWhitelistJson";
import {
  kPrice,
  kPublicSaleLive,
  kWhitelistLive,
  kSaleEnded,
  kOpenSeaLink,
} from "../constants";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import {
  useMintCount,
  useMintCountMax,
  useMintCountReset,
} from "../hooks/useMintCount";

const kAllowedNetworks = [1, 3, 4, 1337];

const kMintUrl = "mint.ethwalkers.io";
// const kCoinbaseWalletShortlink = "https://go.cb-w.com/MjcFkEkeTqb";
const kWalletLimit = 20;

async function changeToHomestead(provider, updateNetwork) {
  try {
    await provider.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
    updateNetwork();
  } catch (switchError) {
    console.error(switchError);
  }
}

const identifyError = (e, setErrorMessage) => {
  // console.log('e', e.message)
  // console.log('e.name', e.name)
  if (e.message.includes("insufficient funds")) {
    setErrorMessage("Insufficient funds in wallet");
  } else if (e.message.includes("Too many claims.")) {
    setErrorMessage("You've already claimed your Tokens");
  }
};

export default function MintSection(props) {
  const {
    provider,
    setProvider,
    metaMaskProvider,
    coinbbaseWalletProvider,
    signer,
    updateSigner,
    network,
    updateNetwork,
    readContracts,
    writeContracts,
    isMobile,
    balanceOf,
    updateBalanceOf,
  } = props;
  const [minted, setMinted] = useState(false);
  const [hash, setHash] = useState(null);
  const [pending, setPending] = useState(false);
  // console.log("ewalkBalanceOf", ewalkBalanceOf);
  const totalAvailableToMint = kWalletLimit - balanceOf;
  let tokenClaimAvailableToMint = totalAvailableToMint;

  const [mintCount, setMintCount] = useMintCount(1, totalAvailableToMint);

  const [mintTokenCount, setMintTokenCount] = useMintCountReset(
    tokenClaimAvailableToMint
  );

  const [errorMessage, setErrorMessage] = useState(null);

  const networkName = network ? network.name : "Loading";

  const networkId = network ? network.chainId : undefined;

  const signerAddress = useAddress(signer);
  const whitelist = useWinnersJson(provider);
  // console.log("whitelist", whitelist);
  console.log("signerAddress", signerAddress);
  const whitelistEntry = useWhitelistEntry(whitelist, signerAddress);
  console.log("whitelistEntry", whitelistEntry);

  const requestFirstAccess = useCallback(
    (event) => {
      const chosenProvider = event.target.attributes["data-provider"].value;
      let thisProvider = metaMaskProvider;
      if (chosenProvider == "coinbasewallet") {
        thisProvider = coinbbaseWalletProvider;
      }

      function handleAccountsChanged(newAccounts) {
        // console.log("handleAccountsChanged", newAccounts);
        if (newAccounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log("Please connect to MetaMask.");
        } else if (newAccounts[0] !== signerAddress) {
          // setCurrentAccount(newAccounts[0]);
          updateSigner();
        }
      }

      const requestAuth = async (thisProvider) => {
        if (!thisProvider) {
          console.error("Invalid provider chosen");
          return;
        }
        thisProvider
          .request({ method: "eth_requestAccounts" })
          .then(handleAccountsChanged)
          .catch((err) => {
            if (err.code === 4001) {
              // EIP-1193 userRejectedRequest error
              // If this happens, the user rejected the connection request.
              console.log("Please connect to MetaMask.");
            } else {
              console.error(err);
            }
          });
      };
      requestAuth(thisProvider);

      // eslint-disable-next-line
    },
    [provider, signerAddress, updateSigner]
  );

  const mint = (numToMint) => {
    setErrorMessage(null);
    // const gasLimit = calcGasLimit(numToMint);
    const val = numToMint * kPrice;
    if (!isEmpty(writeContracts)) {
      writeContracts.ETHWalkersMiami.mintETHWalkersMiami(numToMint, {
        value: ethers.utils.parseUnits(val.toString()),
      })
        .then((result) => {
          setPending(true);
          setHash(result.hash);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("No write contracts :(");
    }
  };

  const whitelistClaim = (numToMint) => {
    setErrorMessage(null);
    const val = numToMint * kPrice;

    if (!isEmpty(writeContracts) && whitelistEntry !== null) {
      writeContracts.ETHWalkersMiami.allowlistETHWalkersMiami(
        numToMint,
        whitelistEntry.index,
        whitelistEntry.sig,
        {
          value: ethers.utils.parseUnits(val.toString()),
        }
      )
        .then((result) => {
          setPending(true);
          setHash(result.hash);
        })
        .catch((e) => {
          console.error("error", e);
          identifyError(e, setErrorMessage);
        });
    }
  };

  useEffect(() => {
    if (!provider || !hash) return;
    console.log("setting interval");
    const intervalId = setInterval(async () => {
      await provider.getTransactionReceipt(hash).then((receipt) => {
        if (receipt?.status === 1) {
          // setMintingStatus(MINTING_STATUS.COMPLETED);
          updateBalanceOf();
          // getMintCount();
          setPending(false);
          setHash(null);
          clearInterval(intervalId);
        }
      });
    }, 2000);
  }, [provider, hash]);

  let errorMessageContent = null;
  if (errorMessage !== null) {
    errorMessageContent = (
      <div className={styles.error_message}>{errorMessage}</div>
    );
  }

  let walletSection = null;
  if (kSaleEnded) {
    walletSection = (
      <div>
        The sale has ended, thanks for minting.
        <br />
        Please visit{" "}
        <a
          className="text-underline text-blue-600 visited:text-purple-600"
          href={kOpenSeaLink}
        >
          OpenSea
        </a>
      </div>
    );
  } else if (!signerAddress && (!isMobile || provider !== null)) {
    walletSection = (
      <div>
        <button
          onClick={requestFirstAccess}
          data-provider="metamask"
          className={styles.connectWallet}
        >
          Connect MetaMask Wallet
        </button>
      </div>
    );
  } else if (!signerAddress && isMobile) {
    walletSection = (
      <div>
        <a href={`https://metamask.app.link/dapp/${kMintUrl}`}>
          <button className={styles.connectWallet}>
            Open in MetaMask Mobile App
          </button>
        </a>
      </div>
    );
  } else if (kAllowedNetworks.indexOf(networkId) === -1) {
    walletSection = (
      <div>
        <button
          onClick={() => {
            changeToHomestead(provider, updateNetwork);
          }}
          className={styles.connectWallet}
        >
          Please switch to ethereum mainnet
        </button>
      </div>
    );
  } else if (pending) {
    const numMint = !!whitelistEntry ? whitelistEntry.count : mintCount;
    walletSection = (
      <div className="mt-3 font-semibold text-center">
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-16 h-16 border-4 border-black border-solid rounded-full animate-spin text-center m-auto"
        ></div>
        Congratulations! Your transaction to mint is pending. <br />
        This page will update when your transaction succeeds.
      </div>
    );
  } else if (balanceOf >= kWalletLimit) {
    // this needs to be changed
    walletSection = (
      <div className="mt-3 font-semibold">
        Congratulations! You've minted {balanceOf} NFTs.
        <br />
        You can view your NFTs on{" "}
        <a
          className="text-underline text-blue-600 visited:text-purple-600"
          href={kOpenSeaLink}
          target="_blank"
        >
          OpenSea
        </a>{" "}
        or below .
      </div>
    );
  } else if (
    whitelistEntry !== null &&
    kWhitelistLive &&
    kWalletLimit > balanceOf
  ) {
    walletSection = (
      <div className="mt-3 font-semibold">
        <div>Congratulations! You're on the whitelist.</div>
        <div className="select-none	inline-block text-sm text-white">
          <span
            className={cn("inline-block cursor-pointer", {
              invisible: mintCount === 1,
            })}
            onClick={() => {
              setMintCount(Math.max(mintCount - 1, 0));
            }}
          >
            <MinusCircleIcon width={30} className="inline-block" />
          </span>
          <span
            className={cn(
              "inline-block p-2 align-middle content-center",
              styles.mint_count
            )}
          >
            {mintCount}
          </span>
          <span
            className={cn("inline-block cursor-pointer", {
              invisible: mintCount === totalAvailableToMint,
            })}
            onClick={() => {
              setMintCount(Math.min(mintCount + 1, totalAvailableToMint));
            }}
          >
            <PlusCircleIcon width={30} className="inline-block" />
          </span>
        </div>

        <button
          onClick={() => {
            whitelistClaim(mintCount);
          }}
          className={cn("ml-4 select-none", styles.mintButton)}
        >
          Mint tokens
        </button>
      </div>
    );
  } else if (kPublicSaleLive) {
    walletSection = (
      <div>
        <div className="select-none	inline-block text-sm text-white">
          <span
            className={cn("inline-block cursor-pointer", {
              invisible: mintCount === 1,
            })}
            onClick={() => {
              setMintCount(Math.max(mintCount - 1, 0));
            }}
          >
            <MinusCircleIcon width={30} className="inline-block" />
          </span>
          <span
            className={cn(
              "inline-block p-2 align-middle content-center",
              styles.mint_count
            )}
          >
            {mintCount}
          </span>
          <span
            className={cn("inline-block cursor-pointer", {
              invisible: mintCount === totalAvailableToMint,
            })}
            onClick={() => {
              setMintCount(Math.min(mintCount + 1, totalAvailableToMint));
            }}
          >
            <PlusCircleIcon width={30} className="inline-block" />
          </span>
        </div>

        <button
          onClick={() => {
            mint(mintCount);
          }}
          className={cn("ml-4 select-none", styles.mintButton)}
        >
          Mint tokens
        </button>
      </div>
    );
  } else {
    // presale
    const balanceOfContent =
      balanceOf > 0 ? (
        <div className={styles.lookforward}>
          You have minted {balanceOf} Portstown NFTs!
        </div>
      ) : null;

    walletSection = (
      <>
        {balanceOfContent}
        <div className={styles.lookforward}>
          Your wallet is connected! See you at July 12th, 2022 1pm PDT for the
          public mint
        </div>
      </>
    );
  }

  const contents = (
    <>
      {walletSection}
      {errorMessageContent}
    </>
  );

  return (
    <div className="derp">
      <div className={cn("z-30", styles.headermiddle)}></div>
      <div className={cn(styles.mint_contents, "text-xl")}>{contents}</div>

      <div
        className={cn(
          styles.footer_inner,
          "pt-8 pb-8 flex justify-center text-center"
        )}
      >
        <p className="text-base text-3xl md:mt-0 md:order-1">
          <span className="text-white">Verified on </span>
          <a
            className="text-underline text-blue-600 visited:text-purple-600"
            target="_blank"
            href="https://etherscan.io/address/0xd56814b97396c658373a8032c5572957d123a49e"
          >
            Etherscan
          </a>
          .
        </p>
      </div>
      <div className="text-center">
        <Link to="/mintpass">
          <h3
            className={cn(
              styles.miami_button,
              "text-center text-white font-semibold text-xl "
            )}
          >
            Mint Pass Holders, click here to mint
          </h3>
        </Link>
        <br />
        <Link to="/survivors">
          <h3
            className={cn(
              styles.miami_button,
              "text-center text-white font-semibold text-xl "
            )}
          >
            Season 1 Human Survivors, click here to mint
          </h3>
        </Link>
        <Link to="/players">
          <h3
            className={cn(
              styles.miami_button,
              "text-center text-white font-semibold text-xl "
            )}
          >
            Season 1 Players, click here to mint
          </h3>
        </Link>
      </div>
    </div>
  );
}
